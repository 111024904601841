// Restore Scrollposition in Modal-Background
exports.shouldUpdateScroll = args => {
  const regex = /trauringe.*/;
  if(regex.test(args.pathname)) {
    return window.innerWidth < 750;
  }
};

// If a product page is visited externally it should not open in modal but the page
exports.onInitialClientRender = () => {
  window.___PAGE_INITIAL_RENDER_COMPLETE = true
};
