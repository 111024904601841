// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-item-index-js": () => import("/opt/build/repo/src/templates/item/index.js" /* webpackChunkName: "component---src-templates-item-index-js" */),
  "component---src-templates-items-index-js": () => import("/opt/build/repo/src/templates/items/index.js" /* webpackChunkName: "component---src-templates-items-index-js" */),
  "component---src-templates-brand-index-js": () => import("/opt/build/repo/src/templates/brand/index.js" /* webpackChunkName: "component---src-templates-brand-index-js" */),
  "component---src-templates-legal-index-js": () => import("/opt/build/repo/src/templates/legal/index.js" /* webpackChunkName: "component---src-templates-legal-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konfiguratoren-js": () => import("/opt/build/repo/src/pages/konfiguratoren.js" /* webpackChunkName: "component---src-pages-konfiguratoren-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-materialien-js": () => import("/opt/build/repo/src/pages/materialien.js" /* webpackChunkName: "component---src-pages-materialien-js" */),
  "component---src-pages-thanks-js": () => import("/opt/build/repo/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-trauringe-js": () => import("/opt/build/repo/src/pages/trauringe.js" /* webpackChunkName: "component---src-pages-trauringe-js" */),
  "component---src-pages-verlobungsringe-js": () => import("/opt/build/repo/src/pages/verlobungsringe.js" /* webpackChunkName: "component---src-pages-verlobungsringe-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

